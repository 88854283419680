<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="financeRefundDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to record Finance Refund to Client date
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalFinanceRefundSubmit()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="financeRefundDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="hrdcData.isTaxpodAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="financeRefundDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit Form</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <!-- Finance Refund to Client Date -->
        <v-col cols="12" sm="6" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.financeRefundToClientDate"
                label="Finance Refund to Client Date (YYYY-MM-DD)"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.financeRefundToClientDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      financeRefundToClientDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
    },
    errorField: {
      applicationNo: null,
      financeRefundToClientDate: null,
    },
    loadingDialog: false,
    financeRefundDialog: false,
    editMode: false,
    btnDisabled: false,
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/create-finance-refund`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc_tax_summit.data.stageId];
    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.financeRefundToClient
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailArToAp
    );

    if (this.editMode) {
      this.apiGetApplicationSingleDetail.fetch();
      this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status) {
            const financeRefundStageId =
              this.hrdcData.stagePriority.financeRefundToClient;
            this.formData.financeRefundToClientDate = moment(
              data.ApplicationLog.find(
                (obj) => obj.stageId == financeRefundStageId
              ).startDate
            ).format("YYYY-MM-DD");
          }

          this.hideLoadingDialog();
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.hideLoadingDialog();
    }
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalFinanceRefundSubmit() {
      this.financeRefundDialog = !this.financeRefundDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tax_summit.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();
      let data = {
        applicationUuid: this.formData.applicationNo,
        financeRefundToClientDate: this.formData.financeRefundToClientDate,
        referById: this.auth.uuid,
        editMode: this.editMode,
      };

      this.apiSubmit.setParams(data);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcTaxSummitApplication",
              query: {
                viewId: this.$store.state.hrdc_tax_summit.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
